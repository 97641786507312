var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap align-center" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap align-center",
          on: {
            click: function($event) {
              return _vm.navigateToFolder(undefined)
            }
          }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "pa-0 ma-0",
              attrs: {
                fab: "",
                "x-small": "",
                color: _vm.folderPath ? "#3F51B5" : "#4F4F4F",
                width: "20px",
                height: "20px"
              }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: "white", size: "18px", id: "docsHomeBtn" } },
                [_vm._v(" " + _vm._s(_vm.mdiHome) + " ")]
              )
            ],
            1
          ),
          _vm.splitPath.length > 0
            ? _c(
                "div",
                [
                  _c("v-icon", [
                    _vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.splitPath, function(p, index) {
        return _c(
          "div",
          {
            key: index + "-" + p,
            staticClass: "d-flex flex-wrap align-center"
          },
          [
            _c(
              "div",
              {
                staticClass: "pointer breadcrumb-text mt-1",
                on: {
                  click: function($event) {
                    return _vm.navigateToFolder(index)
                  }
                }
              },
              [_vm._v(" " + _vm._s(p) + " ")]
            ),
            index !== _vm.splitPath.length - 1
              ? _c(
                  "div",
                  [
                    _c("v-icon", [
                      _vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }