const FIELD_TYPES = {
  STRING: "string",
  DATE: "date",
  NUMBER: "number",
  ESRI_FIELD_TYPE_OID: "esriFieldTypeOID",
  ESRI_FIELD_TYPE_STRING: "esriFieldTypeString",
  ESRI_FIELD_TYPE_INTEGER: "esriFieldTypeInteger",
  ESRI_FIELD_TYPE_SINGLE: "esriFieldTypeSingle",
  ESRI_FIELD_TYPE_DOUBLE: "esriFieldTypeDouble",
  ESRI_FIELD_TYPE_DATE: "esriFieldTypeDate",
  ESRI_FIELD_TYPE_GEOMETRY: "esriFieldTypeGeometry",
  ESRI_FIELD_TYPE_GUID: "esriFieldTypeGUID",
  ESRI_FIELD_TYPE_GLOBAL_ID: "esriFieldTypeGlobalID",
  ESRI_FIELD_TYPE_XML: "esriFieldTypeXML",
};

export default FIELD_TYPES;
