<template>
  <span>
    <v-icon v-if="value === MATCH_CHOICE_VALUES.ON_OR_AFTER" class="py-0 my-0">
      {{ mdiCalendarArrowRight }}
    </v-icon>
    <v-icon
      v-else-if="value === MATCH_CHOICE_VALUES.ON_OR_BEFORE"
      class="py-0 my-0"
    >
      {{ mdiCalendarArrowLeft }}
    </v-icon>
    <v-icon v-else-if="value === MATCH_CHOICE_VALUES.RANGE" class="py-0 my-0">
      {{ mdiCalendarRange }}
    </v-icon>
    <span class="symbol" v-if="value === MATCH_CHOICE_VALUES.EQUALS"> = </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.CONTAINS">
      ⊂
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN"
    >
      ⊄
    </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.GREATER_THAN">
      &#62;
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL"
    >
      ≥
    </span>
    <span class="symbol" v-else-if="value === MATCH_CHOICE_VALUES.LESS_THAN">
      &#60;
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL"
    >
      ≤
    </span>
    <span
      class="symbol"
      v-else-if="value === MATCH_CHOICE_VALUES.DOES_NOT_EQUAL"
    >
      ≠
    </span>
  </span>
</template>

<script>
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import {
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiCalendarRange,
} from "@mdi/js";

export default {
  name: "MatchIcon",
  props: {
    value: String,
  },
  data() {
    return {
      MATCH_CHOICE_VALUES,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      mdiCalendarRange,
    };
  },
};
</script>

<style scoped>
.symbol {
  font-size: 20px;
}
</style>
