<template>
  <VuePdfApp :pdf="src" class="flex-grow-1"></VuePdfApp>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "PdfViewer",
  props: {
    src: String,
  },
  components: {
    VuePdfApp,
  },
};
</script>

<style>
.pdf-app .pdfViewer .page {
  margin: 1px auto 22px auto !important;
  border: none !important;
  background-clip: unset !important;
  border-image: none !important;
  background-color: unset !important;
  box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.8) !important;
}
.pdf-app #mainContainer {
  background-color: rgb(111, 111, 111) !important;
}
</style>
