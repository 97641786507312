const MATCH_CHOICE_VALUES = {
  EQUALS: "equals",
  CONTAINS: "contains",
  DOES_NOT_CONTAIN: "does-not-contain",
  GREATER_THAN: "greater-than",
  GREATER_THAN_OR_EQUAL: "greater-than-or-equal",
  LESS_THAN: "less-than",
  LESS_THAN_OR_EQUAL: "less-than-or-equal",
  DOES_NOT_EQUAL: "does-not-equal",
  ON_OR_AFTER: "on-or-after",
  ON_OR_BEFORE: "on-or-before",
  RANGE: "range",
};

export default MATCH_CHOICE_VALUES;
