var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.value === _vm.MATCH_CHOICE_VALUES.ON_OR_AFTER
        ? _c("v-icon", { staticClass: "py-0 my-0" }, [
            _vm._v(" " + _vm._s(_vm.mdiCalendarArrowRight) + " ")
          ])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.ON_OR_BEFORE
        ? _c("v-icon", { staticClass: "py-0 my-0" }, [
            _vm._v(" " + _vm._s(_vm.mdiCalendarArrowLeft) + " ")
          ])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.RANGE
        ? _c("v-icon", { staticClass: "py-0 my-0" }, [
            _vm._v(" " + _vm._s(_vm.mdiCalendarRange) + " ")
          ])
        : _vm._e(),
      _vm.value === _vm.MATCH_CHOICE_VALUES.EQUALS
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" = ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.CONTAINS
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" ⊂ ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" ⊄ ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.GREATER_THAN
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" > ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≥ ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.LESS_THAN
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" < ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≤ ")])
        : _vm.value === _vm.MATCH_CHOICE_VALUES.DOES_NOT_EQUAL
        ? _c("span", { staticClass: "symbol" }, [_vm._v(" ≠ ")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }