<template>
  <div class="d-flex flex-wrap align-center">
    <div
      class="d-flex flex-wrap align-center"
      @click="navigateToFolder(undefined)"
    >
      <v-btn
        fab
        x-small
        class="pa-0 ma-0"
        :color="folderPath ? '#3F51B5' : '#4F4F4F'"
        width="20px"
        height="20px"
      >
        <v-icon color="white" size="18px" id="docsHomeBtn">
          {{ mdiHome }}
        </v-icon>
      </v-btn>
      <div v-if="splitPath.length > 0">
        <v-icon>
          {{ mdiChevronRight }}
        </v-icon>
      </div>
    </div>
    <div
      class="d-flex flex-wrap align-center"
      v-for="(p, index) of splitPath"
      :key="`${index}-${p}`"
    >
      <div
        class="pointer breadcrumb-text mt-1"
        @click="navigateToFolder(index)"
      >
        {{ p }}
      </div>
      <div v-if="index !== splitPath.length - 1">
        <v-icon>
          {{ mdiChevronRight }}
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiHome, mdiChevronRight } from "@mdi/js";

export default {
  name: "DocsBreadcrumb",
  data() {
    return {
      mdiHome,
      mdiChevronRight,
    };
  },
  props: {
    folderPath: String,
  },
  computed: {
    splitPath() {
      return this.folderPath?.split("/") ?? [];
    },
  },
  methods: {
    navigateToFolder(index) {
      if (index === this.splitPath.length - 1) {
        return;
      }
      this.$emit("navigate-folder", index);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
  color: #3f51b5;
  margin-top: 1px;
}

.breadcrumb-text {
  font-size: 12px;
}
</style>
